import { produce } from "immer";
export const shuffle = (array: number[]): number[] =>
  produce(array, (draft) => {
    draft.sort(() => Math.random() - 0.5);
  });

export const generateArray = (size: number): number[] => {
  let arr = [];
  for (let i = 0; i < size; i++) {
    arr.push(i + 1);
  }
  return arr;
};

export const swap = (array: number[], i1: number, i2: number) =>
  produce(array, (draft) => {
    let temp = draft[i1];
    draft[i1] = draft[i2];
    draft[i2] = temp;
  });

export const replace = (array: number[], index: number, value: any) =>
  produce(array, (draft) => {
    draft[index] = value;
  });
