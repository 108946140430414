export type Action =
  | UpdateSize
  | UpdateSpeed
  | UpdateBars
  | Shuffle
  | UpdateAlgorithm;

export type Size = number;
export type Speed = number;

interface UpdateSize {
  type: ActionType.UPDATE_SIZE;
  payload: Size;
}

interface UpdateSpeed {
  type: ActionType.UPDATE_SPEED;
  payload: Speed;
}

interface UpdateBars {
  type: ActionType.UPDATE_BARS;
  payload: number[];
}

interface Shuffle {
  type: ActionType.SHUFFLE;
}

interface UpdateAlgorithm {
  type: ActionType.UPDATE_ALGORITHM;
  payload: string;
}

export enum ActionType {
  UPDATE_SIZE = "UPDATE_SIZE",
  UPDATE_SPEED = "UPDATE_SPEED",
  UPDATE_BARS = "UPDATE_BARS",
  SHUFFLE = "SHUFFLE",
  UPDATE_ALGORITHM = "UPDATE_ALGORITHM",
}

export const updateSize = (payload: Size): UpdateSize => ({
  type: ActionType.UPDATE_SIZE,
  payload,
});

export const updateSpeed = (payload: Speed): UpdateSpeed => ({
  type: ActionType.UPDATE_SPEED,
  payload,
});

export const updateBars = (payload: number[]): UpdateBars => ({
  type: ActionType.UPDATE_BARS,
  payload,
});

export const shuffle = (): Shuffle => ({ type: ActionType.SHUFFLE });

export const updateAlgorithm = (payload: string): UpdateAlgorithm => ({
  type: ActionType.UPDATE_ALGORITHM,
  payload,
});
