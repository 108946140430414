import styled from "styled-components";

export default styled.div`
  width: 720px;
  height: 400px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  background-color: #eee;
`;
