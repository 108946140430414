import { algorithmNames, generateGrid } from "../algorithms";
import { GridT, Coordinates } from "../domain";

const defaultRows = 20;
const defaultCols = 36;
const defaultAlgorithm = algorithmNames[0];
// State types
export type State = {
  startCell: Coordinates;
  targetCell: Coordinates;
  rows: number;
  cols: number;
  iteration: number;
  nestedIteration: number;
  maze: GridT;
  algorithm: string;
};

export const getDefaultState = (): State => ({
  startCell: [-1, -1],
  targetCell: [-1, -1],
  rows: defaultRows,
  cols: defaultCols,
  iteration: 0,
  nestedIteration: 0,
  maze: generateGrid(defaultRows, defaultCols),
  algorithm: defaultAlgorithm,
});
