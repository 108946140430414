import styled from "styled-components";

interface Props {
  height: number;
}
export default styled.div`
  height: ${(props: Props) => props.height + "%"};
  width: 100%;
  background-color: #ccc;
`;
