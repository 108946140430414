import { swap, replace } from "./helper";

export type Algorithm = (arr: number[]) => number[][];

export const selectionSort: Algorithm = (arr) => {
  let min,
    sorted = true;
  let currArr = arr;
  let sequence: number[][] = [currArr];
  for (let i = 1; i < currArr.length; i++) {
    if (currArr[i] < currArr[i - 1]) {
      sorted = false;
      break;
    }
  }
  if (sorted) return sequence;
  for (let i = 0; i < currArr.length; i++) {
    min = i;
    for (let j = i; j < currArr.length; j++) {
      if (currArr[j] < currArr[min]) min = j;
    }
    currArr = swap(currArr, i, min);
    sequence.push(currArr);
  }
  return sequence;
};

export const bubbleSort: Algorithm = (arr) => {
  let done;
  let currArr = arr;
  let sequence: number[][] = [currArr];
  while (!done) {
    done = true;
    for (let i = 0; i < currArr.length - 1; i++) {
      if (currArr[i + 1] < currArr[i]) {
        currArr = swap(currArr, i, i + 1);
        sequence.push(currArr);
        done = false;
      }
    }
  }
  return sequence;
};

export const insertionSort: Algorithm = (arr) => {
  let currArr = arr;
  let sequence: number[][] = [currArr];
  for (let i = 1; i < currArr.length; i++) {
    if (currArr[i] >= currArr[i - 1]) {
      continue;
    }
    for (let j = i - 1; j >= 0; j--) {
      if (currArr[j + 1] < currArr[j]) {
        currArr = swap(currArr, j, j + 1);
        sequence.push(currArr);
      } else {
        break;
      }
    }
  }
  return sequence;
};

export const mergeSort: Algorithm = (arr) => {
  let sorted = true;
  let currArr = arr;
  let sequence: number[][] = [currArr];
  for (let i = 1; i < currArr.length; i++) {
    if (currArr[i] < currArr[i - 1]) {
      sorted = false;
      break;
    }
  }
  if (sorted) return sequence;

  const sort = (l: number, r: number) => {
    if (r - l === 0) return;
    else if (r - l === 1) {
      if (currArr[r] < currArr[l]) {
        currArr = swap(currArr, l, r);
        sequence.push(currArr);
      }
      return;
    }
    const m = Math.ceil((r + l) / 2);
    sort(l, m);
    sort(m + 1, r);
    let merged = [];
    let i, j;
    for (i = l, j = m + 1; i <= m && j <= r; i) {
      if (currArr[i] < currArr[j]) merged.push(currArr[i++]);
      else merged.push(currArr[j++]);
    }

    if (i > m) merged = merged.concat(currArr.slice(j, r + 1));
    if (j > r) merged = merged.concat(currArr.slice(i, m + 1));

    for (let i = l; i <= r; i++) {
      currArr = replace(currArr, i, merged.shift()!);
      sequence.push(currArr);
    }
  };

  sort(0, currArr.length - 1);
  return sequence;
};

export const quickSort: Algorithm = (arr) => {
  let sorted = true;
  let currArr = arr;
  let sequence: number[][] = [currArr];
  for (let i = 1; i < currArr.length; i++) {
    if (currArr[i] < currArr[i - 1]) {
      sorted = false;
      break;
    }
  }
  if (sorted) return sequence;

  const sort = (left: number, right: number) => {
    let pivot;
    if (currArr.length > 1) {
      pivot = getPivot(left, right);
      if (left < pivot - 1) sort(left, pivot - 1);
      if (pivot < right) sort(pivot, right);
    }
  };

  const getPivot = (left: number, right: number) => {
    let pivot = currArr[Math.floor((right + left) / 2)];
    let i = left;
    let j = right;
    while (i <= j) {
      while (currArr[i] < pivot) {
        i++;
      }
      while (currArr[j] > pivot) {
        j--;
      }
      if (i <= j) {
        currArr = swap(currArr, i, j);
        sequence.push(currArr);
        i++;
        j--;
      }
    }
    return i;
  };

  sort(0, currArr.length - 1);
  return sequence;
};

export const selectAlgorithm = (name: string) => {
  switch (name) {
    case "selectionSort":
      return selectionSort;
    case "bubbleSort":
      return bubbleSort;
    case "insertionSort":
      return insertionSort;
    case "mergeSort":
      return mergeSort;
    case "quickSort":
      return quickSort;
    default:
      return selectionSort;
  }
};

export const algorithmNames = [
  "selectionSort",
  "bubbleSort",
  "insertionSort",
  "mergeSort",
  "quickSort",
];
