import { Coordinates, GridT, CellT, CellType, Side } from "./domain";
import {
  setVisited,
  curriedIsEqualCell,
  getNextDirections,
  setPath,
  push,
  isVisited,
  getKey,
  setWall,
  unshift,
} from "./helper";

export const algorithmNames = ["depthFirstSearch", "breadthFirstSearch"];

export const selectAlgorithm = (name: string) => {
  switch (name) {
    case "depthFirstSearch":
      return depthFirstSearch;
    case "breadthFirstSearch":
      return breadthFirstSearch;
    default:
      return depthFirstSearch;
  }
};

// Maze generation algorithms
export const generateGrid = (() => {
  let cache: { [key: string]: any } = {};
  return (rows: number, cols: number): GridT => {
    const key = getKey([rows, cols]);
    if (cache[key]) return cache[key];

    let grid = [];

    for (let row = 0; row < rows; row++) {
      let newRow = [];
      for (let col = 0; col < cols; col++) {
        const cell: CellT = {
          type: CellType.blank,
          top: row === 0 ? null : [row - 1, col],
          right: col === cols - 1 ? null : [row, col + 1],
          bottom: row === rows - 1 ? null : [row + 1, col],
          left: col === 0 ? null : [row, col - 1],
        };
        newRow.push(cell);
      }
      grid.push(newRow);
    }
    cache[key] = grid;
    return grid;
  };
})();

// using a recursive backtracking algorithme
export const generateMaze = (rows: number, cols: number) => {
  let visited = new Set<string>();
  let grid = generateGrid(rows, cols);
  let result: GridT[] = [];

  result.push(grid);

  const bt = (cursor: Coordinates = [0, 0]) => {
    visited.add(getKey(cursor));
    const randDirections = (getNextDirections(grid, cursor, true, false) as {
      side: Side;
      coord: Coordinates;
    }[]).filter((item) => !visited.has(getKey(item.coord)));
    while (randDirections.length > 0) {
      const next = randDirections.pop()!;
      if (visited.has(getKey(next.coord!))) {
        grid = setWall(grid, cursor, next.side);
        result.push(grid);
      } else bt(next.coord);
    }
  };

  bt();
  return result;
};
// using recursive division

// Path finding algorithms

const breadthFirstSearch = (
  grid: GridT,
  startCell: Coordinates,
  targetCell: Coordinates
) => {
  let resultSequence: GridT[] = [];
  let currGrid = grid;
  let visited: any = {};
  const isTargetCell = curriedIsEqualCell(targetCell);
  const bt = (cursors: Coordinates[]): [number, Coordinates[]] | undefined => {
    if (cursors.length === 0) return;
    let nextCursors: Coordinates[] = [];
    let dict: { [key: number]: number } = {};
    let count = 0;
    for (let i = 0; i < cursors.length; i++) {
      const cursor = cursors[i];
      if (isTargetCell(cursor)) return [i, [cursor]];
      visited[getKey(cursor)] = true;
      currGrid = setVisited(currGrid, cursor);

      // eslint-disable-next-line no-loop-func
      (getNextDirections(currGrid, cursor) as Coordinates[]).forEach((next) => {
        dict[count++] = i;
        nextCursors.push(next);
        if (isVisited(currGrid, next)) console.log(next);
      });
    }
    resultSequence.push(currGrid);
    const result = bt(nextCursors);
    if (result)
      return [dict[result[0]], unshift(result[1], cursors[dict[result[0]]])];
  };

  const shortestPath = bt([startCell]);
  if (shortestPath) {
    for (let cursor of shortestPath[1]) {
      currGrid = setPath(currGrid, cursor);
      resultSequence.push(currGrid);
    }
  }
  return resultSequence;
};

const depthFirstSearch = (
  grid: GridT,
  startCell: Coordinates,
  targetCell: Coordinates
) => {
  let resultSequence: GridT[] = [];
  let firstPath: Coordinates[] = [];
  let currGrid = grid;
  let found = false;
  const isTargetCell = curriedIsEqualCell(targetCell);
  const bt = (cursor: Coordinates, path: Coordinates[] = []) => {
    if (found) return;
    if (isTargetCell(cursor)) {
      firstPath = path;
      found = true;
    }
    currGrid = setVisited(currGrid, cursor);
    resultSequence.push(currGrid);
    const directions = getNextDirections(grid, cursor, true);

    while (directions.length > 0) {
      const next = directions.pop()! as Coordinates;
      if (!isVisited(currGrid, next)) {
        bt(next, push(path, cursor));
      }
    }
  };

  bt(startCell);
  for (let cursor of firstPath) {
    currGrid = setPath(currGrid, cursor);
    resultSequence.push(currGrid);
  }
  return resultSequence;
};
