import styled from "styled-components";
import { CellT, CellType } from "../domain";
const colors = {
  [CellType.blank]: "hsl(0, 50%, 100%)",
  [CellType.visited]: "hsl(60, 80%, 70%)",
  [CellType.optimalPath]: "hsl(120, 80%, 70%)",
};

const isStartColor = "hsl(240, 80%, 70%)";
const isTargetColor = "hsl(0, 80%, 70%)";

interface Props {
  height: number;
  width: number;
  cell: CellT;
  isStart: boolean;
  isTarget: boolean;
}

export default styled.div`
  height: ${(props: Props) => props.height + "px"};
  width: ${(props: Props) => props.width + "px"};
  border: 1px solid ${({ cell }: Props) => colors[cell.type]};
  background-color: ${({ cell, isStart, isTarget }: Props) =>
    isStart ? isStartColor : isTarget ? isTargetColor : colors[cell.type]};

  ${({ cell }: Props) => cell.top === null && "border-top-color: black"};

  ${({ cell }: Props) => cell.right === null && "border-right-color: black"};

  ${({ cell }: Props) => cell.bottom === null && "border-bottom-color: black"};

  ${({ cell }: Props) => cell.left === null && "border-left-color: black"};
`;
