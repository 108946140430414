import React, { useState, useEffect, useReducer } from "react";
import { usePlayback } from "react-playback";
import { RouteComponentProps } from "@reach/router";
import Bars from "./components/Bars";
import Bar from "./components/Bar";
import Button from "../../common/Button";
import { algorithmNames, selectAlgorithm } from "./algorithms";
import { unCamelCase, getDuration } from "../../common/helper";
import reducer from "./state/reducer";
import { getDefaultState } from "./state/state";
import {
    updateSize,
    shuffle,
    updateBars,
    updateAlgorithm,
} from "./state/actions";

export default function SortingViz(props: RouteComponentProps) {
    const [state, dispatch] = useReducer(reducer, undefined, getDefaultState);
    const [size, setSize] = useState(state.size);
    const [frame, isPlaying, PB] = usePlayback<number[]>([], 20000);

    useEffect(() => {
        if (!isPlaying) {
            if (size > 300) dispatch(updateSize(300));
            else if (size < 100) dispatch(updateSize(100));
            else dispatch(updateSize(size));
        } else if (state.size !== size) setSize(state.size);
    }, [size, isPlaying, state.size]);

    const sort = async () => {
        if (isPlaying) return;
        PB.load(
            selectAlgorithm(state.algorithm)(state.bars),
            getDuration(state.bars.length, 30),
            true
        );
    };

    useEffect(() => {
        if (frame) dispatch(updateBars(frame));
    }, [frame]);

    return (
        <div>
            <div className="mt-5">
                <div className="is-flex is-justify-content-space-between">
                    <div>
                        <div className="select is-primary is-small">
                            <select
                                value={state.algorithm}
                                onChange={(e) => {
                                    dispatch(updateAlgorithm(e.target.value));
                                }}
                                disabled={isPlaying}
                            >
                                {algorithmNames.map((name) => (
                                    <option key={name} value={name}>
                                        {unCamelCase(name)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <Button
                            className="ml-5 button is-primary is-small"
                            disabled={isPlaying}
                            onClick={sort}
                        >
                            {isPlaying ? "Playing" : "Play"}
                        </Button>
                    </div>
                    <div>
                        <input
                            className="input is-primary is-small"
                            style={{ maxWidth: "60px" }}
                            type="number"
                            value={size}
                            disabled={isPlaying}
                            onChange={({
                                target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                                setSize(Number(value));
                            }}
                            max={"300"}
                            min={"100"}
                        />
                        <Button
                            className="button is-primary is-small ml-5"
                            disabled={isPlaying}
                            onClick={() => {
                                dispatch(shuffle());
                            }}
                        >
                            shuffle
                        </Button>
                    </div>
                </div>
                <Bars className="mt-3">
                    {state.bars.map((val, i) => (
                        <Bar key={i} height={(val / state.bars.length) * 100} />
                    ))}
                </Bars>
            </div>
        </div>
    );
}
