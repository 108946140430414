import { Action, ActionType } from "./actions";
import { State } from "./state";
import { generateArray, shuffle } from "../helper";

export default (state: State, action: Action): State => {
  let newState = state;

  switch (action.type) {
    case ActionType.UPDATE_SIZE:
      if (state.size !== action.payload)
        newState = {
          ...state,
          size: action.payload,
          bars: shuffle(generateArray(action.payload)),
        };
      break;

    case ActionType.UPDATE_SPEED:
      if (state.speed !== action.payload)
        newState = { ...state, speed: action.payload };
      break;

    case ActionType.SHUFFLE:
      newState = {
        ...state,
        bars: shuffle(state.bars),
        iteration: 0,
      };
      break;

    case ActionType.UPDATE_BARS:
      newState = { ...state, bars: action.payload };
      break;

    case ActionType.UPDATE_ALGORITHM:
      newState = { ...state, algorithm: action.payload };
      break;
  }
  return newState;
};
