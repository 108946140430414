import React from "react";
import { Router, Link } from "@reach/router";
import SortingViz from "./components/pages/SortingViz";
import PathfindingViz from "./components/pages/PathfindingViz";

function App() {
  return (
    <div className="App">
      <div className="container is-flex is-flex-direction-column is-align-items-center pt-5">
        <div>
          {/* style={{ display: "flex", justifyContent: "center" }}> */}
          <Link to="/path">Pathfinding algorithms</Link>
          <Link style={{ marginLeft: "30px" }} to="/sort">
            Sorting algorithms
          </Link>
        </div>
        <Router style={{ margin: "0 auto 0" }}>
          <PathfindingViz path="/path" />
          <SortingViz path="sort" />
          <PathfindingViz default />
        </Router>
      </div>
    </div>
  );
}

export default App;
