import { Action, ActionType } from "./actions";
import { State } from "./state";
import { getRandPairOfCells } from "../helper";

export default (state: State, action: Action): State => {
  let newState = state;

  switch (action.type) {
    case ActionType.UPDATE_MAZE:
      const [startCell, targetCell] = getRandPairOfCells(
        state.rows,
        state.cols
      );
      newState = {
        ...state,
        maze: action.payload,
        startCell,
        targetCell,
      };
      break;

    case ActionType.UPDATE_ALGORITHM:
      newState = { ...state, algorithm: action.payload };
      break;
  }
  return newState;
};
