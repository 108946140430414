import { generateArray, shuffle } from "../helper";
import { algorithmNames } from "../algorithms";

const defaultSize = 100;
const defaultAlgorithm = algorithmNames[0];

export interface State {
  playing: boolean;
  size: number;
  speed: number;
  iteration: number;
  nestedIteration: number;
  bars: number[];
  algorithm: string;
}

export const getDefaultState = (): State => ({
  speed: 500,
  playing: false,
  size: defaultSize,
  iteration: 0,
  nestedIteration: 0,
  bars: shuffle(generateArray(defaultSize)),
  algorithm: defaultAlgorithm,
});
