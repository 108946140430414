import React, { useEffect, useReducer } from "react";
import { usePlayback } from "react-playback";
import { RouteComponentProps } from "@reach/router";
import Grid from "./components/Grid";
import Cell from "./components/Cell";
import { getDefaultState } from "./state/state";
import Button from "../../common/Button";
import Row from "./components/Row";
import reducer from "./state/reducer";
import { unCamelCase, last, getDuration } from "../../common/helper";
import { updateAlgorithm, updateMaze } from "./state/actions";
import { selectAlgorithm, algorithmNames, generateMaze } from "./algorithms";
import { areEqualCells } from "./helper";
import { GridT } from "./domain";

export default function PathfindingViz(props: RouteComponentProps) {
  const [state, dispatch] = useReducer(reducer, undefined, getDefaultState);
  const [frame, isPlaying, PB] = usePlayback<GridT>([], 0);

  const getNewMaze = () => {
    const frames = generateMaze(state.rows, state.cols);
    dispatch(updateMaze(last(frames)!));
    if (!isPlaying) PB.load(frames, 6000, true);
  };

  const solveMaze = () => {
    const frames = selectAlgorithm(state.algorithm)(
      state.maze,
      state.startCell,
      state.targetCell
    );
    return !isPlaying && PB.load(frames, getDuration(frames.length, 30), true);
  };

  useEffect(() => {
    getNewMaze();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="mt-5">
        <div className="is-flex is-justify-content-space-between">
            <div >
              <div className = "select is-primary is-small">
                <select
                  value={state.algorithm}
                  onChange={(e) => {
                    dispatch(updateAlgorithm(e.target.value));
                  }}
                  disabled={isPlaying}
                >
                  {algorithmNames.map((name) => (
                    <option key={name} value={name}>
                      {unCamelCase(name)}
                    </option>
                  ))}
                </select>
              </div>
              <Button className="button is-primary is-small ml-5" disabled={isPlaying} onClick={solveMaze}>
                Solve maze
              </Button>
            </div>
            <Button className="button is-primary is-small is-outlined" onClick={getNewMaze} disabled={isPlaying}>
              Generate new maze
            </Button>
            
        </div>
        <Grid className="mt-3">
          {frame &&
            frame.map((row, rowIdx: number) => (
              <Row key={rowIdx}>
                {row.map((cell, colIdx: number) => (
                  <Cell
                    key={colIdx}
                    height={20}
                    width={20}
                    cell={cell}
                    isStart={areEqualCells(state.startCell, [rowIdx, colIdx])}
                    isTarget={areEqualCells(state.targetCell, [rowIdx, colIdx])}
                  />
                ))}
              </Row>
            ))}
        </Grid>
      </div>
    </div>
  );
}
