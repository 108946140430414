import { GridT } from "../domain";
// Types
export type Action = UpdateMaze | UpdateAlgorithm;

export type ActionBuilder = typeof updateMaze | typeof updateAlgorithm;

export type Speed = number;

interface UpdateMaze {
  type: ActionType.UPDATE_MAZE;
  payload: GridT;
}

interface UpdateAlgorithm {
  type: ActionType.UPDATE_ALGORITHM;
  payload: string;
}

export enum ActionType {
  UPDATE_MAZE = "UPDATE_MAZE",
  UPDATE_ALGORITHM = "UPDATE_ALGORITHM",
}

export const updateMaze = (payload: GridT): UpdateMaze => ({
  type: ActionType.UPDATE_MAZE,
  payload,
});

export const updateAlgorithm = (payload: string): UpdateAlgorithm => ({
  type: ActionType.UPDATE_ALGORITHM,
  payload,
});
