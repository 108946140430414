export enum Side {
  top = "top",
  right = "right",
  bottom = "bottom",
  left = "left",
}

export type Coordinates = [number, number];

export enum CellType {
  blank,
  visited,
  optimalPath,
}

export type CellT = {
  type: CellType;
} & {
  [key in Side.top | Side.left | Side.right | Side.bottom]: Coordinates | null;
};

export type GridT = Array<Array<CellT>>;
