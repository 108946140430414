export const delay = (ms: number): Promise<any> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const unCamelCase = (str: string) =>
  str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const last = <T>(array: T[]) =>
  array.length ? array[array.length - 1] : undefined;

export const getDuration = (length: number, fps: number) =>
  (length / fps) * 1000;
