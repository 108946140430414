import { CellType, Coordinates, Side, GridT } from "./domain";
import { produce } from "immer";

const getOppositeSide = (side: Side) => {
  switch (side) {
    case Side.top:
      return Side.bottom;
    case Side.right:
      return Side.left;
    case Side.bottom:
      return Side.top;
    case Side.left:
      return Side.right;
  }
};

export const getKey = ([row, col]: Coordinates) => "" + row + "," + col;

export const getCell = (grid: GridT, position: Coordinates) =>
  grid[position[0]][position[1]];

export const setWall = (grid: GridT, position: Coordinates, side: Side) =>
  produce(grid, (draft) => {
    if (getCell(grid, position)[side] === null) return;
    getCell(draft, position)[side] = null;
    switch (side) {
      case Side.top:
        getCell(draft, [position[0] - 1, position[1]])[
          getOppositeSide(side)
        ] = null;
        break;
      case Side.bottom:
        getCell(draft, [position[0] + 1, position[1]])[
          getOppositeSide(side)
        ] = null;
        break;
      case Side.left:
        getCell(draft, [position[0], position[1] - 1])[
          getOppositeSide(side)
        ] = null;
        break;
      case Side.right:
        getCell(draft, [position[0], position[1] + 1])[
          getOppositeSide(side)
        ] = null;
        break;
    }
  });

export const setType = (grid: GridT, position: Coordinates, type: CellType) =>
  produce(grid, (draft) => {
    getCell(draft, position).type = type;
  });

export const setVisited = (grid: GridT, position: Coordinates) =>
  getCell(grid, position).type === CellType.blank
    ? setType(grid, position, CellType.visited)
    : grid;

export const setPath = (grid: GridT, position: Coordinates) =>
  produce(grid, (draft) => {
    if (getCell(draft, position).type === CellType.visited)
      getCell(draft, position).type = CellType.optimalPath;
  });

export const isVisited = (grid: GridT, position: Coordinates) =>
  getCell(grid, position).type === CellType.visited;

export const curriedIsEqualCell = (target: Coordinates) => (
  cell: Coordinates
) => areEqualCells(target, cell);

export const areEqualCells = (c1: Coordinates, c2: Coordinates) =>
  c1[0] === c2[0] && c1[1] === c2[1];

export const push = <T>(arr: T[], element: T) =>
  produce(arr, (draft) => {
    // workaround to bypass immer typescript overload bug
    (draft as T[]).push(element);
  });

export const unshift = <T>(arr: T[], element: T) =>
  produce(arr, (draft) => {
    // workaround to bypass immer typescript overload bug
    (draft as T[]).unshift(element);
  });

export const getNextDirections = (
  grid: GridT,
  cursor: Coordinates,
  random = false,
  positionOnly = true
) => {
  let result: { side: Side; coord: Coordinates }[] = Object.keys(Side)
    .map((side: any) => ({
      side: side as Side,
      coord: getCell(grid, cursor)[side as Side]!,
    }))
    .filter(({ coord }) => coord !== null && !isVisited(grid, coord));

  if (random) result.sort(() => Math.random() - 0.5);
  if (positionOnly) return result.map((item) => item.coord);
  else return result;
};

export const getRandom = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getRandomCell = (rows: number, cols: number): Coordinates => [
  getRandom(0, rows - 1),
  getRandom(0, cols - 1),
];

export const getRandPairOfCells = (
  rows: number,
  cols: number
): [Coordinates, Coordinates] => {
  const startCell = getRandomCell(rows, cols);
  let targetCell;

  do {
    targetCell = getRandomCell(rows, cols);
  } while (areEqualCells(startCell, targetCell));

  return [startCell, targetCell];
};
